@charset "UTF-8";

@font-face {
  font-family: "fontastic";
  src:url("fontastic.eot");
  src:url("fontastic.eot?#iefix") format("embedded-opentype"),
  url("fontastic.woff") format("woff"),
  url("fontastic.ttf") format("truetype"),
  url("fontastic.svg#fontastic") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "fontastic" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontastic" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info-icon:before {
  content: "\61";
}
.icon-lock-icon:before {
  content: "\62";
}
.icon-mastercard-icon:before {
  content: "\63";
}
.icon-logo-fidel:before {
  content: "\64";
}
.icon-visa-icon:before {
  content: "\66";
}
.icon-dropdown-arrows:before {
  content: "\67";
}
.icon-cancel:before {
  content: "\69";
}
.icon-upload-cloud:before {
  content: "\6b";
}
.icon-refresh-icon:before {
  content: "\65";
}
.icon-x:before {
  content: "\6e";
}
.icon-circle:before {
  content: "\6a";
}
.icon-angle-left:before {
  content: "\6c";
}
.icon-angle-right:before {
  content: "\6d";
}
.icon-cta-arrow:before {
  content: "\6f";
}
.icon-angle-down:before {
  content: "\70";
}
.icon-check:before {
  content: "\71";
}